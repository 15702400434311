const serverProtocol = window.location.protocol;
const serverHost = window.location.hostname;


export default {
  apiURL: (host = 'dirservice') => `${serverProtocol}//${serverHost}/${host}`,
  appURL: (host = 'dirservice') => `${serverProtocol}//${serverHost}/${host}`,
  timeout: 1000,

  /**
   * Generates the authorization header object.
   * @param accessToken - The access token for authentication.
   * @returns The authorization header object.
   */
  authHeader (accessToken?: string) {
    if (accessToken) {
      // for Node.js Express back-end
      return { 
      'Authorization': `Bearer ${accessToken}`,
      'Content-type': 'application/json'
      };
  } else {
      return { 
      'Content-type': 'application/json'
      };
  }
  },

  connData(extraConfig?: any, accessToken?: string): any {
      return {...{
          baseURL: this.apiURL(),
          headers: this.authHeader(accessToken),
      }, ...extraConfig}
  }
};

